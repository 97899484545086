import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
// import { layoutGenerator } from 'react-break';

// import ReactGA from 'react-ga';
// import env from "react-dotenv";

// shared components
import HeaderBar from './components/HeaderBar';
import FooterBar from './components/FooterBar';

// page components
// import FormPage from './components/FormPage';
import AdminPage from './components/AdminPage';
import HomePage from './components/HomePage';
import ResultsPage from './components/ResultsPage';
import HeaderResultsPage from './components/HeaderResultsPage';
// import Custom314Leg from './components/Custom314Pac';
// import Custom314Fed from './components/Custom314Fed';
// import CustomPotomac1 from './components/CustomPotomac1';
// import Chuck from './components/Chuck';
import TOS from './TOS';
import Privacy from './Privacy';
// import LinkRedirecter from './components/LinkRedirecter.js';

import styles from './styles/global.module.css';


const AppWithRouterAccess = () => {

  // const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);

  // const customPageElement = (
  //   <div className={styles.window}>
  //     <div className={styles.centerForm}>
  //       <HeaderBar/>
  //       <FormPage/>
  //     </div>
  //     <FooterBar/>
  //   </div>
  // )

  const adminPageElement = (
    <div className={styles.window}>
      <div className={styles.centerForm}>
        <HeaderBar/>
        <AdminPage/>
      </div>
      <FooterBar/>
    </div>
  )

  const homePageRoute = 
    <Route exact path='/'
      element={
        <div className={styles.window}>
          <div className={styles.centerForm}>
            <HeaderBar/>
            <HomePage/>
          </div>
          <FooterBar/>
        </div>}
    />;

  const resultsPageRoute = 
    <Route exact path='/results'
      element={
        <div className={styles.window}>
          <div className={styles.centerForm}>
            <ResultsPage/>
          </div>
        </div>}
    />;

  const headerResultsPageRoute = 
    <Route exact path='/headerresults'
      element={
        <div className={styles.window}>
          <div className={styles.centerForm}>
            <HeaderResultsPage/>
          </div>
        </div>}
    />;

  return (
    <>
        <Routes>
          {homePageRoute}
          {resultsPageRoute}
          {headerResultsPageRoute}
{/*          <Route path='/admin' element={adminPageElement} />;
          <Route path='/custom' element={adminPageElement} />;*/}
          <Route path='/TOS' element ={<TOS />}/>
          <Route path='/privacy' element={<Privacy />}/>
{/*          <Route path='/url' element={<LinkRedirecter />}/>
          <Route path='/314leg' element={<Custom314Leg />}/>
          <Route path='/314fed' element={<Custom314Fed daily={false}/>}/>
          <Route path='/314feddaily' element={<Custom314Fed daily={true}/>}/>
          <Route path='/potomac-3' element={<CustomPotomac1 />}/>
          <Route path='/chuck' element={<Chuck />}/>*/}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </>
  );

};

export default AppWithRouterAccess;



