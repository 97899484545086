import React from 'react';
import { readableDateString2 } from '../Utils.js'
import styles from '../../styles/listingStyles.module.css';

const DomainListing = ( props ) => {

    let listing_struct = (
      <>
        <p className={styles.titleLine}>
          <b>{props.data.source + ": "}</b>
          {"(" + readableDateString2(props.data.date) + ") "}
          <a href={'/url?urlid=' + encodeURIComponent(props.data.url)} target="_blank" rel="noreferrer">
            {props.data.title}
          </a>
        </p>
        {/*<p>{props.data.date ? ("(" + readable_time_string(props.data.date) + ") ") : <></>}</p>*/}
{/*        <ul>
          <li>{props.data.description}</li>
        </ul>*/}
        <br />
      </>
    )

    return listing_struct
  }


export default DomainListing;