import React from 'react';
import { readable_time_string } from '../Utils.js';
import styles from '../../styles/listingStyles.module.css';

const Listing = ( props ) => {

    console.log(props.data.snippets);

    let snippets = props.data.snippets;

    let listing_struct = (
      <>
        <p className={styles.titleLine}>
          <b>{props.data.source + " "}
          <a href={'/url?urlid=' + encodeURIComponent(props.data.url)} target="_blank" rel="noreferrer">
            {props.data.title}
          </a></b>
        </p>
        <p>
          {props.data.date ? ("(" + readable_time_string(props.data.date) + ") ") : <></>}
        </p>
        <ul>
          {snippets.map(s => 
            <li key={s.substring(0,100)} dangerouslySetInnerHTML={{__html: s}} />
          )}
        </ul>
        <br></br>
      </>
    )

    return (snippets && snippets.length > 0) ? listing_struct : <></>
  }


export default Listing;