import React from 'react';
import { useNavigate } from 'react-router-dom';
// import ReactGA from 'react-ga';

import styles from '../styles/HeaderBar.module.css'
import logo from '../images/logo.png'

const HeaderBar = () => {

  	const navigate = useNavigate();

  	const openAirtableLink = () => {

		// // log the event
		// ReactGA.event({
		//   category: 'User',
	 //      action: 'contact-us'
	 //    });

	    window.open('https://airtable.com/shrbItqs7TdEOuQr1')
  	}

	return (
		<div className={styles.headerBarContainer}>
			<img onClick={() => navigate('/')} className={styles.logoImage} src={logo} alt="Logo" />
			<button onClick={openAirtableLink} 
					className={styles.getStartedButton}>
					{"Sign up"}
			</button>
		</div>
	)
}

export default HeaderBar;