import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';
import AppWithRouterAccess from './AppWithRouterAccess';

ReactDOM.render(
  <>
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700&display=swap');
    </style>
    <Router>
      <AppWithRouterAccess/>
    </Router>
  </>,
  document.getElementById('root')
);
