import React from 'react';

import styles from '../styles/HeaderBar.module.css'
import icon from '../images/icon.png'

const FooterBar = () => {

  return (
    <div className={styles.footer}>
      <img className={styles.logoIcon} src={icon} alt="Icon" />
      <p className={styles.disclosure}>{"info@clipbook.io"}</p>
      <p className={styles.disclosure}>{"2022 Clipbook. All Rights Reserved."}</p>
    </div>
  )
}

export default FooterBar;