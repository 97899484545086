import React from 'react';
import styles from '../../styles/listingStyles.module.css';

const TweetListing = ( props ) => {

  let article = props.data;

  if (!article.text || article.text === undefined) { return <></> }

  let tweetText = article.text.replaceAll('/n', ' ');
  let verified = article.author.verified ? 
                  <p style={{"display": "inline"}}><img style={{"height":"15px", "marginLeft": "2px", "marginRight": "3px"}}
                        src="https://i.ibb.co/1XVVVbb/image.png" 
                        alt="verified" /></p>
                 : <></>;

    let listing_struct = (
      <div>
        <p className={styles.titleLine} style={{"marginBottom":"0px", "fontWeight":"normal", "display":"inline"}}>
          <b><a href={article.link}> {article.author.name}</a></b>
        </p>
        {verified}
        <p className={styles.titleLine} style={{"display":"inline"}} dangerouslySetInnerHTML={{__html: tweetText}} />
        <br/><br/>
      </div>
    )

    return listing_struct
  }


export default TweetListing;