import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import env from "react-dotenv";

// components
import Listing from './listing_types/Listing.js';
import DomainListing from './listing_types/DomainListing.js';
import TweetListing from './listing_types/TweetListing.js';

// utils
import { query_date_string, readable_date_string } from './Utils.js';

// styles
// import styles from '../styles/results.module.css';
// import gs from '../styles/global.module.css';

const ResultsPage = (props) => {

  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const [peopleList, setPeopleList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [tweetList, setTweetList] = useState([]);

  useEffect(() => {

    process_query()
      .then(response => response ? response.json() : null)
      .then(data => {

        if (!data) { return null }

          console.log(data);

        setPeopleList(data['peopleList']);
        setTermList(data['termList']);
        setDomainList(data['domainList']);
        setTweetList(data['twitterList']);

        console.log(peopleList);

        setLoading(false);

      });


  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const process_query = async () => {

    setLoading(true);

    if (!state.isCustom) {

      let url = env.REACT_APP_ROOT_URL + 'v6/getResultsForClient' +
        '?date=' + query_date_string(state.startDate) +
        '&clientName=' + encodeURIComponent(state.clientName) + 
        '&sendReport=false' + 
        '&isClient=' + state.isClient

      console.log(url);

      let req = new Request(url);
      return await fetch(req);

    } else {
      let principal_q = state.principal === '' ? '' : '&principal=' + encodeURIComponent(state.principal)
      let org_q = state.organization === '' ? '' : '&organization=' + encodeURIComponent(state.organization)
      let poi_q = state.queryList === '' ? '' : '&poiList=' + encodeURIComponent(state.queryList)
      let term_q = state.termList === '' ? '' : '&termList=' + encodeURIComponent(state.termList)
      let domain_q = state.termList === '' ? '' : '&domainList=' + encodeURIComponent(state.domainList)

      let isSpanish = '&isSpanish=' + state.includeSpanish

      if (principal_q + org_q + poi_q + term_q + domain_q !== '') {
        let url = env.REACT_APP_ROOT_URL + 'v5/results/' +
          '?date=' + query_date_string(state.startDate) + isSpanish + principal_q + org_q + poi_q + term_q + domain_q;

        let req = new Request(url);
        return await fetch(req);
      }
      
    }

    return null;

  };

  const sendReport = async () => {

    var url = env.REACT_APP_ROOT_URL + 'v6/getResultsForClient' +
        '?date=' + query_date_string(state.startDate) +
        '&clientName=' + encodeURIComponent(state.clientName) + 
        '&sendReport=true' + 
        '&isClient=' + state.isClient

    var req = new Request(url);
    await fetch(req);
    alert('success');

  }

  var date_string = readable_date_string(new Date(), 0)

  return (
    <div style={{'padding': '50px'}}>
    {loading ? <p>Loading...</p> :
      <div style={{'display': 'flex', 'flexDirection': 'column'}}>
        <div>
          <button onClick={() => {sendReport()}} 
                  style={{'float': 'right'}}>
                  {'Send Report'}
          </button>
        </div>
        <div>
          <h2>{"Press Clips: " + date_string}</h2> <br/>
          {peopleList.map(people => <Listing data={people}/>  )}

          {termList.length > 0 ? <>
            <h2>{"Terms of Interest: " + date_string}</h2> <br/>
            {termList.map(term => <Listing data={term}/> )}
          </> : <></> }

          {tweetList.length > 0 ? <>
            <h2>{"Tweet Mentions: " + date_string}</h2> <br/>
            {tweetList.map(term => <TweetListing data={term}/> )}
          </> : <></> }

          <h2>{"Top Headlines: " + date_string}</h2> <br/>
          {domainList.map(domain => <DomainListing data={domain}/> )}
        </div>
      </div>
    }
    </div>
  )
}

export default ResultsPage;
