import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import env from "react-dotenv";

// styles
import styles from "../styles/FormPage.module.css";

// components
import ListingSpecial from "./listing_types/ListingSpecial.js";
import TweetListing from './listing_types/TweetListing.js';

const HeaderResultsPage = (props) => {

  const { state } = useLocation();
  const [results, setResults] = useState(null);

  useEffect(() => {

    let url = env.REACT_APP_ROOT_URL + "v8/header?clientName=" + encodeURIComponent(state.clientName);
    let req = new Request(url);

    fetch(req)
      .then((response) => (response ? response.json() : null))
      .then((data) => {
        if (!data) {
          return null;
        }

        console.log(data);

        setResults(data);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ padding: "50px" }}>
      {!results ? (
        <p>Loading...</p>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <br />
            <p>
              {`to: ${results['to']}`}
            </p>
            <p>{`cc: adam@clipbook.io, isabelle@clipbook.io`}</p>
            <br />
            {results && results['result'].map((result) => (
              <>
                <h2 className={styles.title}>{result[0] + " Clips"}</h2>
                {result[1] ? (
                  <>
                    <h2 className={styles.subtitle}>{result[1]}</h2>
                    <br />
                  </>
                ) : (
                  <br />
                )}
                {result[2]["news"].map((people) => (
                  <ListingSpecial data={people} boolean={true} />
                ))}

                {result[2]["twitter"].map((tweets) => (
                  <TweetListing data={tweets}/>
                ))}


                <br />
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderResultsPage;
