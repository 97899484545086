import React from "react";
import { readable_time_string, readable_time_string2 } from "../Utils.js";
import styles from "../../styles/listingStyles.module.css";

const convertPrincipalListToString = (list, boolean) => {
  if (boolean) {
    return list.join(", ");
  }

  let newList = list.map((l) => l.split("AND")[0].trim());
  return newList.join(", ");
};

const ListingSpecial = (props) => {
  let snippets = props.data.snippets;

  let listing_struct = (
    <>
      <p className={styles.titleLine}>
        <b>
          {props.data.source + " "}
          <a
            href={"/url?urlid=" + encodeURIComponent(props.data.url)}
            target="_blank"
            rel="noreferrer"
          >
            {props.data.title}
          </a>
        </b>
      </p>
      <p>
        {props.data.date ? (
          "(" + props.year ? readable_time_string2(props.data.date) : readable_time_string(props.data.date) + ") "
        ) : (
          <></>
        )}
      </p>
      {snippets && snippets.length > 0 ? (
        <ul>
          {snippets.map((s) => (
            <li
              key={s.substring(0, 100)}
              dangerouslySetInnerHTML={{ __html: s }}
            />
          ))}
        </ul>
      ) : (
        <ul>
          <li>
            {"Article may contain the following mentions: " +
              convertPrincipalListToString(
                props.data.principal,
                props.boolean
              ) +
              " " +
              convertPrincipalListToString(
                props.data.organization,
                props.boolean
              ) + 
              " " + 
              convertPrincipalListToString(
                props.data.people,
                props.boolean
              ) + 
              " " +
              convertPrincipalListToString(
                props.data.terms,
                props.boolean
              )
            }
          </li>
        </ul>
      )}
      <br></br>
    </>
  );

  return listing_struct;
};

export default ListingSpecial;