import React, { useEffect } from 'react';

// import ReactGA from 'react-ga';

//icons
import { BiTimer } from 'react-icons/bi'
import { FaDollarSign } from 'react-icons/fa'
import { MdInsights } from 'react-icons/md'

// styles
import styles from '../styles/HomePage.module.css';
// import gs from '../styles/global.module.css';

//images
import home_image from '../images/home_image.png';

const HomePage = () => {

  // const navigate = useNavigate();

  // useEffect(() => {
  //   ReactGA.pageview('/');
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const InfoTab = ( props ) => (
    <div className={styles.infoTab}>
      <div className={styles.infoTabIcon}>
        {props.icon}
      </div>
      {/*<p className={styles.infoTabText}>{props.text}</p>*/}
      <p className={styles.infoTabText} dangerouslySetInnerHTML={{__html:props.text}} />
    </div>
  )

  const titleText = 'Clipbook democratizes media monitoring, aggregation, and analysis services through a robust and easy-to-use platform'
  const firstBoxText = 'No more manual “press clips” - <b>save hundreds of hours</b> by letting us aggregate and write your daily media briefings';
  const secondBoxText = 'Receive <b>actionable insights</b> to improve your communications presence';
  const thirdBoxText = 'Save <b>thousands of dollars</b> with our agile and cost-effective solution';

  const icon_style = {'height':'50px', 'width':'50px', 'color': '#4BAAFC'}

  const openAirtableLink = () => {

    // // log the event
    // ReactGA.event({
    //   category: 'User',
    //   action: 'contact-us'
    // });

    window.open('https://airtable.com/shrbItqs7TdEOuQr1')
  }

  return (
    <>
      <div className={styles.homePageWindow}>
        <h1 style={{'padding': '20px'}}>{titleText}</h1>
        <button onClick={openAirtableLink} 
                className={styles.contactusButton}>
                {"Sign up for a free demo"}
        </button>
        <img src={home_image} alt="Graphic"/>
        <div className={styles.bottomBar}>
          <InfoTab icon={<BiTimer style={icon_style} />} text={firstBoxText}/>
          <InfoTab icon={<MdInsights style={icon_style} />} text={secondBoxText}/>
          <InfoTab icon={<FaDollarSign style={icon_style} />} text={thirdBoxText}/>
        </div>
      </div>
    </>
  )
}

export default HomePage;

