export const readable_date_string = (date, offset) => {
  var d = new Date(date);
  d = new Date(d.setDate(d.getDate() + offset));

  var month = '' + (d.getMonth() + 1);
  var day = '' + d.getDate();

  if (month.length < 2) {month = '0' + month;}
  if (day.length < 2) {day = '0' + day;}

  return [month, day].join('/');
}

export const query_date_string = (date) => {
  var d = new Date(date);

  var month = '' + (d.getMonth() + 1);
  var day = '' + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2) {month = '0' + month};
  if (day.length < 2) {day = '0' + day};

  return [year, month, day].join('-');
}

export const readable_time_string = (date) => {
  var d = new Date(date);
  var time_string = d.toLocaleString('en-US', {timeZone: 'America/New_York'}).split(', ')[1]

  const weekdayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  var weekday = weekdayNames[d.getDay()]
  var month = monthNames[d.getMonth()]
  var day = '' + d.getDate();

  var temp = time_string.split(' ')
  time_string = temp[0].split(':').slice(0,2).join(':') + " " + temp[1]
  
  return weekday + ", " + month + " " + day  + ", " + time_string;

}

export const readable_time_string2 = (date) => {
  var d = new Date(date);

  const weekdayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  var weekday = weekdayNames[d.getDay()]
  var month = monthNames[d.getMonth()]
  var year = d.getYear() + 1900;
  var day = '' + d.getDate();

  // var temp = time_string.split(' ')
  // time_string = temp[0].split(':').slice(0,2).join(':') + " " + temp[1]
  
  return weekday + ", " + month + " " + day  + ", " + year;

}

export const readableDateString2 = (date) => {

  var d = new Date(date);

  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  return months[d.getMonth()] + ' ' + d.getDate();

}

export const readableDateString3 = () => {

  var d = new Date();

  var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  return months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();

}